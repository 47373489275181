<template>
    <v-container fluid class="mb-12">
        <v-row>
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                                >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="p-0">
                    <v-container fluid class="p-3">
                        <v-row class="mb-1">
                            <v-col cols="12">
                                <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="mr-3">mdi-filter</v-icon> Filter: </h6>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="col-6" sm="6" md="2">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        dense
                                        v-model="date_from"
                                        label="Start Period"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        type="month"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2" >
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        dense
                                        v-model="date_to"
                                        label="End Period"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        type="month"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-slot-machine"
                                    clearable
                                    solo
                                    dense
                                    v-model="machineType"
                                    :items="machineTypes"
                                    item-value="mach_type"
                                    item-text="descr"
                                    label="Machine Type"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="(event) => fetchMachineID(event)"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-state-machine"
                                    clearable
                                    solo
                                    dense
                                    v-model="machId"
                                    :items="machIds"
                                    item-value="mach_id"
                                    item-text="descr"
                                    label="Machine Id"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-compost"
                                    clearable
                                    solo
                                    dense
                                    v-model="commodity"
                                    :items="commodities"
                                    item-value="commodity_id"
                                    item-text="descr"
                                    label="Commodity Id"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="(event) => [fetchProdThick(event), fetchProdWidth(event)]"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-watermark"
                                    clearable
                                    solo
                                    v-model="brand"
                                    :items="brands"
                                    item-value="descr"
                                    item-text="descr"
                                    label="Brand"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="(event) => [fetchProdThick(event), fetchProdWidth(event)]"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-arrow-up-down"
                                    clearable
                                    solo
                                    dense
                                    v-model="thick"
                                    :items="thickness"
                                    item-value="thick"
                                    item-text="thick"
                                    label="Thickness"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-arrow-left-right"
                                    clearable
                                    solo
                                    dense
                                    v-model="width"
                                    :items="widths"
                                    item-value="width"
                                    :item-text="item => $store.getters.convertToCurrency(item.width)"
                                    label="Width"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-quality-high"
                                    clearable
                                    solo
                                    dense
                                    v-model="quality"
                                    :items="qualities"
                                    item-value="quality_id"
                                    :item-text="item => item.quality_id + ' - ' + item.descr"
                                    label="Quality"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" class="mt-5">
                            <v-col class="col-6" sm="6" md="2">
                                <v-btn class="border-12" block  style="padding: 21px;"
                                elevation="2" small @click="clear()">Clear</v-btn>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-btn class="border-12" block style="padding: 21px;"
                                color="info" elevation="2" small @click="fetchData()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col cols="12">
                <v-card id="sumScShipmentAnalysis" outlined height="450">
                    <v-card-text>
                        <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="mr-3">mdi-poll</v-icon> Chart Summary: </h6>
                        <v-img
                        class="text-center mt-5 mx-auto"
                        lazy-src="@/assets/empty_data.png"
                        max-width="300"
                        src="@/assets/empty_data.png"
                        v-if="showData"
                        ></v-img>
                        <div id="container1" style="height: 400px; width: 100%;"></div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-5 mb-12">
            <v-col cols="12" xs="12" 
            sm="6" 
            md="3">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container2" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="3">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container3" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="3">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container4" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="3">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container5" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="3">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container6" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="3">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container7" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="3">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container8" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="3">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container9" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data(){
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Sunrise Mill',
                disabled: false,
                href: '/admin/sm',
                },
                {
                text: 'Analysis',
                disabled: false,
                href: '#',
                },
                {
                text: 'Production Analysis',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            machineType: '',
            machineTypes: [],
            machId: '',
            machIds: [],
            commodity: '',
            commodities: [],
            brand: '',
            brands: [],
            thick: '',
            thickness: [],
            width: '',
            widths: [],
            quality: '',
            qualities: [],
            date_from: '',
            modal: false,
            modal_to: false,
            date_to: '',
            showData: true,
            x: window.matchMedia("(max-width: 991px)"),
            searchItem: '',
            header:[
                { text: 'Machine Type', value: 'machine_type' , align:'start'},
                { text: 'Machine ID', value: 'machine_id' , align:'left'},
                { text: 'Commodity', value: 'commodity_descr' , align:'left'},
                { text: 'Brand', value: 'brand' , align:'left'},
                { text: 'Quality', value: 'quality_id' , align:'left', width:50},
                { text: 'Thick', value: 'thick' , align:'right'},
                { text: 'Width', value: 'width' , align:'right'},
                { text: 'Weight (TON)', value: 'wgt' , align:'right'},
            ],
            headersColumn:{
                'Period': 'period', 
                'Thick Name': 'thick_name',
                'Machine Type' : 'machine_type',
                'Machine ID' : 'machine_id',
                'Commodity' : 'commodity_descr',
                'Brand' : 'brand',
                'Quality' : 'quality_id',
                'Thick': 'thick',
                'Width': 'width',
                'Weight (KG)' : 'wgt'
            },
            detailData:[],
            detail: null,
            dialog: false,
            showDataDetail: true
        }  
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        console.log("me")
        await this.fetchMachineType()
        await this.fetchCommodity()
        await this.fetchBrand()
        await this.fetchProdQuality()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        tr_datatable(item) {
            var rowClass = 'tr_datatable'
            return rowClass;
        },
        clear(){
            this.date_from = ''
            this.date_to = ''
            this.machineType = ''
            this.machId = ''
            this.commodity = ''
            this.brand = ''
            this.thick = ''
            this.width = ''
            this.quality = ''
        },
        async fetchMachineType(){
            var reqBody = {
                'mach_type': this.machineType ? this.machineType : ''
            }
            const respData = await backendApi.fetchCore('/v2/master/sm/machine_type', reqBody, false, false, false)
            if (respData.status === 200) {
                this.machineTypes = respData.data
            }
        },
        async fetchMachineID(event){
            if (event == '' || event == null) {
                this.machId = ''
            } else {
                var reqBody = {
                    'mach_type': this.machineType ? this.machineType : ''
                }
                const respData = await backendApi.fetchCore('/v2/master/sm/machine_id', reqBody, false, false, false)
                if (respData.status === 200) {
                    this.machIds = respData.data
                }
            }
        },
        async fetchCommodity(){
            var reqBody = {
                'commodity_id': this.commodity ? this.commodity : ''
            }
            const respData = await backendApi.fetchCore('/v2/master/sm/commodity', reqBody, false, false, false)
            if (respData.status === 200) {
                this.commodities = respData.data
            }
        },
        async fetchBrand(){
            var reqBody = {
                'brand_id': this.brand ? this.brand : ''
            }
            const respData = await backendApi.fetchCore('/v2/master/sm/prod_brand', reqBody, false, false, false)
            if (respData.status === 200) {
                this.brands = respData.data
            }
        },
        async fetchProdThick(event){
            if (event == '' || event == null) {
                this.thick = ''
            } else {
                var reqBody = {
                    'brand_id': this.brand ? this.brand : '',
                    'commodity_id': this.commodity ? this.commodity : ''
                }
                const respData = await backendApi.fetchCore('/v2/master/sm/prod_thick', reqBody, false, false, false)
                if (respData.status === 200) {
                    this.thickness = respData.data
                }
            }
        },
        async fetchProdWidth(event){
            if (event == '' || event == null) {
                this.width = ''
            } else {
                var reqBody = {
                    'brand_id': this.brand ? this.brand : '',
                    'commodity_id': this.commodity ? this.commodity : ''
                }
                const respData = await backendApi.fetchCore('/v2/master/sm/prod_width', reqBody, false, false, false)
                if (respData.status === 200) {
                    this.widths = respData.data
                }
            }
        },
        async fetchProdQuality(){
            var reqBody = {
                'quality_id': this.quality ? this.quality : ''
            }
            const respData = await backendApi.fetchCore('/v2/master/sm/prod_quality', reqBody, false, false, false)
            if (respData.status === 200) {
                this.qualities = respData.data
            }
        },
        async fetchData(){
            
            this.$store.dispatch('setOverlay', true)

            if (this.date_from == '') {
                this.$store.dispatch('setOverlay', false)

                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: "Please fill start Period",
                    visible: true
                };
                return false
            }

            var start = this.date_from ? new Date(this.date_from).toISOString().substr(0, 7).replace(/-/g,"") : ''
            var end = this.date_to ? new Date(this.date_to).toISOString().substr(0, 7).replace(/-/g,"") : ''

            var reqBody = {
                'start': start,
                'end': end,
                'mach_type': this.machineType ? this.machineType : '',
                'mach_id': this.machId ? this.machId : '',
                'commodity_id': this.commodity ? this.commodity : '',
                'brand': this.brand ? this.brand : '',
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
                'quality_id': this.quality ? this.quality : ''
            }

            const respData = await backendApi.fetchCore('/v2/sm/production-analysis', reqBody, false, false, false)
            const respData2 = await backendApi.fetchCore('/v2/sm/production-analysis/wgt_target', reqBody, false, false, false)
            if (respData.status === 200) {
                this.showDataDetail = true
                this.getPieChart([], 'container2', '', '', true)
                this.getPieChart([], 'container3', '', '', true)
                this.getPieChart([], 'container4', '', '', true)
                this.getPieChart([], 'container5', '', '', true)
                this.getPieChart([], 'container6', '', '', true)
                this.getPieChart([], 'container7', '', '', true)
                this.getPieChart([], 'container8', '', '', true)
                this.getPieChart([], 'container9', '', '', true)


                var container = "container1";

                var inputJson = respData.data
                const transformedData = {};

                var inputJson2 = respData2.data
                const transformedData2 = {};

                inputJson.forEach(item => {
                    const machType = item.mach_type.trim() == "" ? 'empty' : item.mach_type.toLowerCase();
                    if (!transformedData[machType]) {
                        transformedData[machType] = [];
                    }
                    transformedData[machType].push({
                        x: new Date( item.period.substr(0, 4) + '-' + item.period.substr(4, 6)),
                        y: parseFloat(item.total_wgt),
                        mach_type: item.mach_type
                    });
                });

                inputJson2.forEach(item => {
                    const machType = item.mach_type.trim() == "" ? 'empty' : item.mach_type.toLowerCase();
                    if (!transformedData2[machType]) {
                        transformedData2[machType] = [];
                    }
                    transformedData2[machType].push({
                        x: new Date( item.period.substr(0, 4) + '-' + item.period.substr(4, 6)),
                        y: parseFloat(item.total_target),
                        mach_type: item.mach_type
                    });
                });

                var pickling = (transformedData.pk) ? transformedData.pk : []
                var rolling = (transformedData.rl) ? transformedData.rl : []

                var target_pickling = (transformedData2.pk) ? transformedData2.pk : []
                var target_rolling = (transformedData2.rl) ? transformedData2.rl : []
                console.log(target_pickling);
                console.log(target_rolling);

                await this.getChart(pickling, rolling, "Pickling", "Rolling",target_pickling, target_rolling, "Target Pickling", "Target Rolling",container, 'column');

                $('html, body').animate({
                    scrollTop: $("#sumScShipmentAnalysis").offset().top
                }, 1200)

                this.showData = false

                this.$store.dispatch('setOverlay', false)
            }
        },
        getChart(dt4, dt2, name4, name2, dt4a, dt2a, name4a, name2a, container, type){
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: 'Production Summary',
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: 'Weight',
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                axisX: {
                    // labelFormatter: function (e) {
                    //     console.log(new Date(e.value - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 7));
                    //     // var month = e.value.substr(0, 4) + '-' + e.value.substr(5, 7)
                    //     // console.log(month);
                    //     return (new Date(e.value - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7);
                    // },
                    interval: 3,
                    intervalType: "month"
                },
                axisY: {
                    // scaleBreaks: {
                    //     autoCalculate: true,
                    //     type: "wavy"
                    // },
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "TON",
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries,
                    fontSize: 11
                },
                data: [
                    {
                        type: "column",
                        click: this.showDetail,
                        name: name4,
                        indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        // indexLabelFontColor: "#FFF",
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "vertical",
                        // color: "#75cfb8",
                        // yValueFormatString: "#,###,,,.##",
                    },
                    {
                        type: "column",
                        click: this.showDetail,
                        name: name2,
                        indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        // indexLabelFontColor: "#FFF",
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "vertical",
                        // color: "#75cfb8",
                        // yValueFormatString: "#,###,,,.##",
                    },
                    {
                        type: "line",
                        click: this.showDetail,
                        name: name4a,
                        indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        // indexLabelFontColor: "#FFF",
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "horizontal",
                        // color: "#75cfb8",
                        // yValueFormatString: "#,###,,,.##",
                    },
                    {
                        type: "line",
                        click: this.showDetail,
                        name: name2a,
                        indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        // indexLabelFontColor: "#FFF",
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "horizontal",
                        // color: "#75cfb8",
                        // yValueFormatString: "#,###,,,.##",
                    }
                ]
            });

            chart.options.data[0].dataPoints = dt4;
            chart.options.data[1].dataPoints = dt2;
            chart.options.data[2].dataPoints = dt4a;
            chart.options.data[3].dataPoints = dt2a;

            // this.showDefaultText(chart, "No Data Found!");
            var x = window.matchMedia("(max-width: 991px)");
            if (x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        toggleDataPointVisibility(e) {
            if(e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
                e.dataPoint.y = e.dataPoint.actualYValue;
                e.dataPoint.actualYValue = null;
                e.dataPoint.indexLabelFontSize = null;
                e.dataPoint.indexLabelLineThickness = null;
                e.dataPoint.legendMarkerType = "circle";
            } 
            else {
                e.dataPoint.actualYValue = e.dataPoint.y;
                e.dataPoint.y = 0;
                e.dataPoint.indexLabelFontSize = 0;
                e.dataPoint.indexLabelLineThickness = 0; 
                e.dataPoint.legendMarkerType = "cross";
            }
                e.chart.render();
        },
        showDefaultText(chart, text) {
            var dataPoints = chart.options.data[0].dataPoints;
            var isEmpty = !(dataPoints && dataPoints.length > 0);

            if (!isEmpty) {
                for (var i = 0; i < dataPoints.length; i++) {
                isEmpty = !dataPoints[i].y;
                if (!isEmpty)
                    break;
                }
            }

            if (!chart.options.subtitles)
                chart.options.subtitles = [];
            if (isEmpty) {
                chart.options.subtitles.push({
                text: text,
                verticalAlign: 'center',
                });
                chart.options.data[0].showInLegend = false;
            } else {
                chart.options.data[0].showInLegend = true;
            }
        },
        async showDetail(e){
            console.log(e);
            this.detail = {
                period: (new Date(new Date(e.dataPoint.x) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7).replace(/-/g,"")
            }
            this.detailData = []
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'period': (new Date(new Date(e.dataPoint.x) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7).replace(/-/g,""),
                'mach_type': e.dataPoint.mach_type,
                'mach_id': this.machId ? this.machId : '',
                'commodity_id': this.commodity ? this.commodity : '',
                'brand': this.brand ? this.brand : '',
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
                'quality_id': this.quality ? this.quality : ''
            }

            const respDataWidth = await backendApi.fetchCore('/v2/sm/production-analysis/width', reqBody, false, false, false)
            const respDataThick = await backendApi.fetchCore('/v2/sm/production-analysis/thick', reqBody, false, false, false)
            const respDataQuality = await backendApi.fetchCore('/v2/sm/production-analysis/quality_id', reqBody, false, false, false)
            const respDataThickName = await backendApi.fetchCore('/v2/sm/production-analysis/thick_name', reqBody, false, false, false)
            const respDataBrand = await backendApi.fetchCore('/v2/sm/production-analysis/brand', reqBody, false, false, false)
            const respDataCommodity = await backendApi.fetchCore('/v2/sm/production-analysis/commodity_id', reqBody, false, false, false)
            const respDataMachType = await backendApi.fetchCore('/v2/sm/production-analysis/mach_type', reqBody, false, false, false)
            const respDataMachId = await backendApi.fetchCore('/v2/sm/production-analysis/mach_id', reqBody, false, false, false)

            if (respDataWidth.status === 200 && respDataThick.status === 200 && respDataQuality.status === 200 && respDataThickName.status === 200 && respDataBrand.status === 200 && respDataCommodity.status === 200 && respDataMachType.status === 200 && respDataMachId.status === 200) {
                this.showDataDetail = false
                var param = (new Date(new Date(e.dataPoint.x) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7).replace(/-/g,"");
                var label_detail = "Periode: "+param;

                for (var i = 0; i < respDataWidth.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataWidth.data.length; i++) {

                        dt_detail.push({  label: respDataWidth.data[i].width,  y: parseInt(respDataWidth.data[i].total_wgt), legendText: respDataWidth.data[i].width });

                    }
                    var container_detail = 'container2';
                    var title_detail = 'by Width'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataThick.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataThick.data.length; i++) {

                        dt_detail.push({  label: respDataThick.data[i].thick,  y: parseInt(respDataThick.data[i].total_wgt), legendText: respDataThick.data[i].thick });

                    }
                    var container_detail = 'container3';
                    var title_detail = 'by Thick'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataQuality.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataQuality.data.length; i++) {

                        dt_detail.push({  label: respDataQuality.data[i].quality_id,  y: parseInt(respDataQuality.data[i].total_wgt), legendText: respDataQuality.data[i].quality_id });

                    }
                    var container_detail = 'container4';
                    var title_detail = 'by Quality Id'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataThickName.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataThickName.data.length; i++) {

                        dt_detail.push({  label: respDataThickName.data[i].thick_name,  y: parseInt(respDataThickName.data[i].total_wgt), legendText: respDataThickName.data[i].thick_name });

                    }
                    var container_detail = 'container5';
                    var title_detail = 'by Thick Name'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataBrand.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataBrand.data.length; i++) {

                        dt_detail.push({  label: respDataBrand.data[i].brand,  y: parseInt(respDataBrand.data[i].total_wgt), legendText: respDataBrand.data[i].brand });

                    }
                    var container_detail = 'container6';
                    var title_detail = 'by Brand'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataCommodity.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataCommodity.data.length; i++) {

                        dt_detail.push({  label: respDataCommodity.data[i].commodity_id,  y: parseInt(respDataCommodity.data[i].total_wgt), legendText: respDataCommodity.data[i].commodity_id });

                    }
                    var container_detail = 'container7';
                    var title_detail = 'by Commodity Id'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataMachType.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataMachType.data.length; i++) {

                        dt_detail.push({  label: respDataMachType.data[i].mach_type,  y: parseInt(respDataMachType.data[i].total_wgt), legendText: respDataMachType.data[i].mach_type });

                    }
                    var container_detail = 'container8';
                    var title_detail = 'by Mach Type'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataMachId.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataMachId.data.length; i++) {

                        dt_detail.push({  label: respDataMachId.data[i].mach_id,  y: parseInt(respDataMachId.data[i].total_wgt), legendText: respDataMachId.data[i].mach_id });

                    }
                    var container_detail = 'container9';
                    var title_detail = 'by Mach Id'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }
            }

            this.$store.dispatch('setOverlay', false)

        },
        getPieChart(dt_detail, container_detail, title_detail, label_detail, destroy = false){
            var pie_chart = new CanvasJS.Chart(container_detail, {
                animationEnabled: true,
                exportEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: title_detail,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: label_detail,
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                legend: {
                    itemclick: this.toggleDataPointVisibility
                },
                data: [{

                    type: "pie",
                    percentFormatString: "#0.##",
                    indexLabel: "{label} #percent%",
                    indexLabelFontSize: 12,

                }]
            });

            pie_chart.options.data[0].dataPoints = dt_detail;
            this.showDefaultText(pie_chart, "No Data Found!");
            var x = window.matchMedia("(max-width: 991px)");

            if (x.matches) {

                for(var i = 0; i < pie_chart.options.data.length; i++){
                    pie_chart.options.data[i].indexLabelFontSize = 6;
                }
                pie_chart.render();
            }
            pie_chart.render();

            if (destroy == true) {
                pie_chart.destroy();
            }
        }
    },    
}
</script>

<style>

</style>